.authorized {
  flex: 0 0 auto;
  height: 37px;
  width: 37px;

  background: var(--coral);
  color: var(--white);
  border: none;
  border-radius: 25px;

  line-height: 37px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;

  cursor: pointer;
}

.sign_in {
  font-weight: bold;
  padding: 0 15px;
  white-space: nowrap;
  height: 47px;
}
