.badge {
  position: relative;
  width: 100%;
  margin-right: 30px;

  .count {
    position: absolute;
    top: -7px;
    right: -5px;
    border-radius: 25px;
    height: 25px;
    width: 25px;
    z-index: 1;

    background: var(--coral);
    color: white;
    font-weight: bold;

    display: grid;
    place-items: center;
    pointer-events: none;
  }
}

.isThankYouPage{
  pointer-events: none;
  .count{
    opacity: 0;
  }
}
