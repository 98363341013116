.frame {
  height: auto;
  width: clamp(30vw, 490px, 80vw);
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  overflow: auto;

  .payment_container {
    margin: 25px 20px;
  }

  .title {
    font-weight: bold;
    font-size: 21px;
    text-align: start;
    color: var(--dark-blue-grey);
    margin-top: 4px;
    margin-left: 48px;
    text-transform: capitalize;
  }

  .close {
    margin-top: 21px;
    align-self: end;
    margin-right: 26px;
  }
}
