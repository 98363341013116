.frame {
  height: auto;
  width: clamp(30vw, 410px, 80vw);

  .container {
    padding: 45px 70px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      text-align: center;
      padding-bottom: 55px;
      color: var(--big_stone);
    }

    .buttons_bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 15px;
    }

    .button {
      font-weight: bold;
    }
  }
}
