.languages {
  position: relative;

  .selected {
    display: block;
    height: 47px;
    width: 47px;
    color: white;
    background-color: var(--big_stone);
    border: solid 1px var(--white);
  }

  .options {
    position: absolute;
    width: 100%;
    display: none;
    flex-direction: column;

    .option {
      padding: 0;
      width: 100%;
      height: 40px;
      border: none;
      background-color: var(--big_stone);
      color: white;
      cursor: pointer;
      z-index: 1;

      &:hover {
        color: var(--havelock-blue);
      }
    }
  }

  &:hover {
    .selected {
      border-bottom: none;
    }

    .options {
      border: solid 1px var(--white);
      border-top: none;
      display: flex;

    }
  }
}




