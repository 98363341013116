.container {
  width: 100%;
  height: 94px;
  background-color: var(--big_stone);
  padding: 0 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 5;
  }

  .right_section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
}

.link {
  text-decoration: none;
}

@media screen and (max-width: 991px) {
  .container {  
    .right_section {
      display: none;
    }
  }
}