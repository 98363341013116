$height: 40px;

.countries {
  position: relative;

  .selected {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    height: $height;
    margin-right: 35px;
    color: white;
    background-color: var(--big_stone);
    border: none;

    .text {
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .options {
    position: absolute;
    display: none;
    flex-direction: column;
    width: 213px;
    .optionsWrapper {
      display: flex;

      flex: 1 1;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      color: white;
      padding: 5px 15px;
      background-color: var(--big_stone);
      z-index: 1;
      &:hover {
        background-color: var(--bluey-grey);
      }
    }

    .option {
      border: none;
      background-color: transparent;
      color: white;
    }
  }

  &:hover:not([aria-disabled="true"]) {
    .selected {
      color: var(--havelock_blue);

      svg {
        fill: var(--havelock_blue);
      }
    }

    .options {
      z-index: 1000;
      border: solid 1px var(--bluey-grey);
      display: flex;
      padding: 14px 0 21px;
      background-color: var(--big_stone);
    }
  }
}

.languages {
  position: relative;

  .selected {
    display: block;
    height: $height;
    margin-right: 35px;
    color: white;
    background-color: var(--big_stone);
    border: none;

    .text {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .options {
    position: absolute;
    width: 100%;
    display: none;
    flex-direction: column;

    .option {
      padding: 0;
      width: 100%;
      height: 40px;
      border: none;
      background-color: var(--big_stone);
      color: white;
      cursor: pointer;
      z-index: 1;

      &:hover {
        color: var(--havelock-blue);
      }
    }
  }

  &:hover:not([aria-disabled="true"]) {
    .selected {
      color: var(--havelock_blue);
    }

    .options {
      border: solid 1px var(--white);
      display: flex;
    }
  }
}

.icon {
  border-radius: 50%;
}
