@use "./typography";
@use "./colors";

@import-normalize;

* {
  font-family: Montserrat, Roboto, sans-serif;
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
