.container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 146px;
  background-color: var(--big_stone);

  .block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: fit-content;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    height: 100%;
    &.socialColumn {
      text-align: center;
    }
    .social_container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
     
    }
    .terms{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
    }
    &.addressWrapper {
      align-items: flex-end;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.16px;
      color: var(--white);
      text-decoration: none;
      &.power {
        color: var(--havelock_blue);
      }
      &.blue {
        color: var(--havelock_blue);
        &:hover {
          color: var(--white);
        }
      }
      &.address {
        line-height: 1.7;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    min-height: 10.19rem;
    height: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
 
    .block {
      grid-column-gap: 3rem;
      grid-template-rows: auto;
      grid-auto-columns: 1fr;
    }
  
    .column {
      justify-content: flex-start;
      &.socialColumn {
        gap: 0;
      }
      .text {
        letter-spacing: .01rem;
        font-size: 1rem;
        line-height: 1.81rem;
        &.power {
          margin-top: 1.5rem;
          color: var(--havelock_blue);
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    min-height: 10.19rem;
    height: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
 
    .block {
      grid-row-gap: 3.81rem;
      grid-template-columns: 1fr;
    }
  
    .column {
      justify-content: center;
      align-items: center;
      &.socialColumn {
        order: 3;
      }
      &.addressWrapper {
        align-items: center;
        justify-content: center;
      }
    }
  }
}