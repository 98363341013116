.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  max-width: 100vw;
  background-color: var(--porcelain);

  .main {
    width: 100%;
    height: 100%;
    position: relative;

    &.fit_window {
      height: calc(100vh - 94px - 146px);
    }

    .back {
      position: absolute;
      top: 15px;
      left: 2%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      border: none;
      font-size: 16px;
      font-weight: bold;
      color: var(--havelock_blue);
      background: transparent;
      cursor: pointer;
    }
  }
}
