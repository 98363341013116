.frame {
  height: auto;
  width: clamp(30vw, 410px, 40vw);

  background: var(--big_stone);

  .close_container {
    display: flex;
    justify-content: flex-end;

    .close {
      padding: 10px;
      margin-top: 10px;
      margin-right: 10px;
      svg {
        stroke: var(--catskill_white);
      }
    }
  }

  .block {
    padding: 0 13%;

    .text {
      font-size: 21px;
      text-align: center;
      color: var(--white);
    }

    .head {
      @extend .text;
      margin-top: 5px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .description {
      @extend .text;
      margin-top: 33px;
    }

    .warning {
      @extend .text;
      margin-top: 33px;
      color: var(--coral);
    }

    .buttons_bar {
      display: grid;
      place-content: center;
      padding: 35px 0;
      margin-bottom: 10px;

      .button {
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
