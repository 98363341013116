.btnContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 21px;
  margin-right: 26px;
}

.frame {
  max-height: 60vh;
  width: clamp(30vw, 690px, 80vw);
  padding: 0 60px;

  display: grid;
  grid-auto-rows: auto auto 1fr auto;
  color: var(--dark-blue-grey);

  .title {
    font-weight: bold;
    font-size: 21px;
    text-align: start;
    color: var(--dark-blue-grey);
    margin-bottom: 25px;
    text-transform: none;
  }

  .table {
    display: grid;
    grid-template-columns: 80% 10% 10%;
    overflow: auto;

    &.head {
      height: fit-content;
    }

    &.body {
      height: 100%;
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      border: solid 1px var(--ghost);
      background: var(--ghost);
    }

    &::-webkit-scrollbar-thumb {
      border: solid 5px var(--havelock_blue);
      background: var(--havelock_blue);
      border-radius: 20px;
      height: 105px;
    }

    .header {
      text-align: start;
      font-size: 14px;
      font-weight: 400;
      color: var(--blue-grey);
      padding: 10px 0;
      border-bottom: 1px solid var(--ghost);
    }

    .body {
      display: flex;
      border-bottom: 1px solid var(--ghost);
      height: 40px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 0;

      &.price {
        justify-content: flex-end;
      }

      &.button {
        padding-left: 15px;
        width: 100%;
      }
    }
  }

  .total {
    width: 90%;
    text-align: end;
    padding: 10px 0;
    font-weight: 700;
    color: var(--dark-blue-grey);
  }

  .buttons_bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    margin-top: 35px;
    padding-bottom: 30px;

    .button {
      font-weight: 700;
    }
  }
}
