.payment_form {
  width: inherit;
  margin: 0 23px;
  display: flex;
  flex-direction: column;
  .information {
  & button {
    font-size: 16px;
    font-weight: bold;
    --main: var(--dark-blue);
    --btn-border : none;
  }
}
  .input_section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    --payment-input-color: rgb(48, 49, 61);
    --payment-input-border-color: #D9D9D9;
    --payment-input-border-width: 1px;

    &.error:not(:focus-within) {
      --payment-input-color: #df1b41;
      --payment-input-border-color: #df1b41;
      --payment-input-border-width: 2px;
    }

    .label {
      font-size: 15px;
      color: rgb(48, 49, 61);
    }

    .field {
      padding: 12px;
      font-size: 16px;
      border-color: var(--payment-input-border-color);
      border-width: var(--payment-input-border-width);
      color: var(--payment-input-color);
    }
  }

  .pay_btn {
    background: inherit;
    border: none;
    line-height: 1.5em;
    font-weight: bold;
    color: var(--white);
    text-transform: capitalize;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 42px;
    margin: 24px 28px 0 31px;
    padding: 12px 23px 11px 22px;
    border-radius: 21px;
    background-color: var(--havelock_blue);
    align-self: center;
    cursor: pointer;

    &[disabled] {
      background-color: #D9D9D9;
      color: #fff;
      cursor: not-allowed;
    }
  }
}

