.box {
  background-color: var(--big_stone);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 45px;

  .btn {
    background-color: var(--coral);
    border-radius: 4px;
    width: 190px;
    color: white;
    font-weight: bold;
  }

  .page {
    font-size: 40px;
    color: var(--coral);
  }

  .link {
    text-decoration: none;
  }
}