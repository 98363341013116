$height: 60px;

.countries {
  position: relative;
  width: 100%;
  .seletedButton {
    display: flex;
    align-items: center;
    padding: 0 30px;
    justify-content: flex-start;
    border: none;
    .selected {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      height: $height;
      color: var(--white);
      background-color: var(--big_stone);
      .text {
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
      }
    }
    .btnArrow {
      position: fixed;
      right: 30px;
    }
  }

  .options {
    padding: 20px 0;
    position:absolute;
    border: none;
    top: -150px;
    z-index: 5000;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: var(--big_stone);
    right: -100%;
    width: 100%;
    transition: 0.8s;
    .iconWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      margin-left: 21px;
      .btn {
        gap: 20px;
        justify-content: flex-start;
        border: none
      }
      .btnArrowBack {
        font-size: 16px;
        font-weight: bold;
        color: var(--havelock-blue);
      }
    }
    .optionsWrapper {
      display: flex;
      flex: 1 1;
      justify-content: flex-start;
      align-items: center;
      gap: 40px;
      color: var(--white);
      padding: 15px 60px;
      background-color: var(--big_stone);
      z-index: 1;
      .option {
        border: none;
        background-color: transparent;
        &:hover {
          color: var(--havelock-blue);
        }
      }
    }
  }
  .activeOptions {
    right: 0;
    transition: 0.4s;
  }

  &:hover:not([aria-disabled="true"]) {
    .selected {
      color: var(--havelock_blue);

      svg {
        fill: var(--havelock_blue);
      }
    }
    .options {
      z-index: 1000;
      display: flex;
    }
  }
}

.languages {
  position: relative;
  width: 100%;
  .seletedButton {
    display: flex;
    align-items: center;
    padding: 0 25px;
    justify-content: flex-start;
    border: none;
    .selected {
      display: block;
      height: $height;
      width: 45px;
      color: var(--white);
      background-color: var(--big_stone);
      align-items: center;
      justify-content: center;
      display: flex;

      .text {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .btnArrow {
      position: fixed;
      right: 30px;
    }
  }

  .options {
    padding: 40px 0;
    position: relative;
    border: none;
    top: -280px;
    z-index: 5000;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--big_stone);
    right: -100%;
    width: 100%;
    transition: 0.8s;
    .iconWrapper {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-left: 40px;
      margin-bottom: 20px;
      .btn {
        gap: 20px;
        justify-content: flex-start;
        border: none
      }
      .btnArrowBack {
        font-size: 16px;
        font-weight: bold;
        color: var(--havelock-blue);
      }
    }
    .option {
      display: flex;
      justify-content: flex-start;
      padding: 20px 80px;
      border: none;
      background-color: var(--big_stone);
      color: var(--white);
      cursor: pointer;
      z-index: 1;
      &:hover {
        color: var(--havelock-blue);
      }
    }
  }
  .activeOptions {
    right: 0;
    transition: 0.4s;
  }

  &:hover:not([aria-disabled="true"]) {
    .selected {
      color: var(--havelock_blue);
    }

    .options {
      z-index: 1000;
      display: flex;
    }
  }
}
