.infoContainer{
  display: flex;
  align-items: center;
  margin-top: 50px;
  .info{
    margin: 0 20px;
    & > div:first-child{
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.16px;
      color: var(--big_stone);
      margin-bottom: 4px;
    }
    & > div:last-child{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.14px;
      color: var(--gray);
    }
  }
}

.cardExpiryElement{
  width: 100%;
  border: 1px solid var(--border-color);
  padding: 8px 10px;
  min-height: 35px;
  font-size: 14px;
  font-weight: normal;
}

.formGroup{
  padding: 0;
  width: 100%;
  border: 0;
  margin-left: 0;
}

.formGroup:first-child{
  margin-right: 7px;
}

.formGroup:last-child{
  margin-left: 7px;
}

.formRow{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.formRowNext{
  display: flex;
  flex-direction: column;
}

.CardElement{
  width: 100%;
  border: 1px solid var(--border-color);
  padding: 8px 10px;
  min-height: 35px;
  font-size: 14px;
  font-weight: normal;
}

.cardCvcElement{
  width: 100%;
  border: 1px solid var(--border-color);
  padding: 8px 10px;
  min-height: 35px;
  font-size: 14px;
  font-weight: normal;
}

.modalPayment{
  .submitBtn{
    width: 144px;
    margin: 0 auto;
    button{
      padding: 14px 30px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.16px;
      text-align: center;
      background: var(--button-background);
    }
  }
}

.paymentInfoContainer{
  padding: 20px;
  max-width: 400px;
 & button {
   --main : var(--havelock_blue);
   --btn-border: var(--havelock_blue);
   font-size: 16px;
   font-weight: bold;

 }
}

.formPaymentMethod{
  width: 100%;

  .titleBilling{
    margin-top: 25px;
    margin-bottom: 13px;
    margin-left: 11px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    color: var(--dark-grey-blue);
  }
  .sr-combo-inputs {
    margin: 20px 0;
  }

  .sr-input {
    font-size: 16px;
  }

  .btn {
    font-size: 16px;
  }
}

.createPaymentMethodBtn{
  max-width: 320px;
  --button-background: var( --blue-dark);
  --button-border: var(--blue-dark);
  margin-top: 10px;

}

.postalCodeContainer{
  display: flex;
  margin-top: 13px;
  margin-bottom: 20px;
  input:first-child{
    margin-right: 7px;
  }
  input:last-child{
    margin-left: 7px;
  }
}

.expirationContainer{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.errorsText{
  color: red;
  margin-bottom: 20px;
}
