.container {
    background: var(--catskill_white);
    overflow: auto;

  .title {
    padding: 60px 0 7px 0;
    margin: 0 auto;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: 0.48px;
    text-align: center;
    color: var(--big_stone);
  }

  .subtitle {
    margin: 7px auto 53px auto;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.43;
    letter-spacing: 0.21px;
    text-align: center;
    color: var(--big_stone);
  }

  .content{
    margin: 0 auto;
    background: #fff;
    padding: 60px 80px;
    width: 1200px;
    margin-bottom: 92px;
    .textBlock{
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      padding: 20px 0;
      color: var(--terms-color);
      .textBold{
        font-size: 18px;
        padding: 0;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.39;
        letter-spacing: normal;
        color: var(--terms-color);
        font-weight: bold;
      }
    }

    .textBold{
      padding: 20px 0;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      color: var(--terms-color);
      font-weight: bold;
    }
    .textBlockBy{
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      color: var(--terms-color);
    }

    .blockServices{
      display: flex;
      flex-direction: row;
      padding: 20px 10px;
      color: var(--black);
      padding-bottom: 0;
      & > div:last-child{
        margin-left: 20px;
      }
    }


    .textTitle{
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      color: var(--terms-color);
    }
  }
}

@media screen and (max-width: 991px) {
  .container {
  
    .title {
      font-size: 30px;
      line-height: normal;
      letter-spacing: 0.3px;
      padding: 20px 58px 0 58px;
      text-transform: uppercase;
      margin: 30px auto 0 auto;
    }
  
    .subtitle {
      font-size: 18px;
      line-height: 2.83;
      height: 51px;
      margin: 7px 32px 26px;
      letter-spacing: 0.18px;
    }
  
    .content{
      margin: 26px 32px;
      background: #fff;
      padding: 26px;
      width: auto;
      margin: 40x 32px;
      .textBlock{
        font-size: 16px;
        line-height: 25px;
        letter-spacing: normal;
        padding: 15px 0;
        .textBold{
          font-size: 16px;
          padding: 15px 0;
          line-height: 25px;
        }
      }
  
      .textBold{
        padding: 12px 0;
        font-size: 16px;
        padding: 15px 0;
        line-height: 25px;
      }
      .textBlockBy{
        font-size: 18px;
        padding: 15px 0;
        line-height: 25px;
        letter-spacing: normal;
      }  
  
      .textTitle{
        font-size: 21px;
        line-height: 25px;
        padding: 15px 0;
        text-transform: uppercase;
      }

      .blockServices{
       padding-bottom: 15px;
      }
    }
  }
}
