:root {
  --coral: #f5574a;
  --big_stone: #142f41;
  --border-color: #d9d9d9;
  --blue-dark: #1b223a;
  --button-background: #1b223a;
  --dark-grey-blue: #172b29;
  --havelock_blue: #5fa2db;
  --dark-blue: #5399d2;
  --seafoam-blue: #5fd4db;
  --white: #ffffff;
  --ghost: #ced2d9;
  --dark-blue-grey: #1c2143;
  --dark-blue-grey-2: #122430;
  --light-grey-2: #f7f8fb;
  --blue-grey: #98a5b7;
  --porcelain: #e7eaec;
  --light-blue-grey: #ced2d9;
  --bluey-grey: #98a5b7;
  --document-bg: #435967;
  --grey-dark: #909aa2;
  --terms-color: #132723;
  --timber-dark-green: #0f2c29;
  --white-20-percent: rgba(255, 255, 255, 0.2);
  --catskill_white: #edf0f7;
}
