.container {
  display: flex;
  flex-direction: column;

  .label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.required::after {
      content: '*';
    }
  }

  .error {
    min-height: 18px;
    white-space: pre-wrap;
    font-size: 14px;
    color: var(--coral);
    margin-bottom: 5px;
  }
}
