.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  margin-top: 10px;
  align-self: center;
  color: var(--dark-blue-grey);

  .header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: var(--bluey-grey);
  }

  .divider {
    width: 100%;
    height: 1px;
    border: solid 1px var(--ghost);
    margin-top: 12px;
  }

  .product_list {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  .item_title {
    font-size: 16px;
    color: var(--big_stone);
  }

  .total {
    align-self: end;
    margin-top: 14px;
    font-weight: bold;
  }
}
